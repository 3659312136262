<template>
  <div class="d-flex">
    <v-autocomplete
      :items="contacts"
      :multiple="multi"
      item-text="name"
      item-value="contactId"
      v-model="localValue"
      :placeholder="
        multi ? $t('contacts.selectContacts') : $t('contacts.selectContact')
      "
      :label="
        multi ? $t('contacts.selectContacts') : $t('contacts.selectContact')
      "
      chips
      small-chips
      deletable-chips
    >
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title v-text="item.name"></v-list-item-title>
          <v-list-item-subtitle v-text="item.email"></v-list-item-subtitle>
          <v-list-item-subtitle
            v-text="item.phoneNumber"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>

    <v-btn
      class="ml-2 align-self-center"
      small
      @click="createDialog = true"
      fab
      color="primary"
      v-if="!noForm"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="createDialog" max-width="600">
      <contact-form @submit="contactFormSubmit"></contact-form>
    </v-dialog>
  </div>
</template>

<script>
import ContactForm from "@/components/contact/ContactForm";
import { mapState, mapActions } from "vuex";

export default {
  name: "ContactSelector",

  components: {
    ContactForm,
  },

  props: {
    multi: {
      type: Boolean,
      default: false,
    },

    value: {
      type: Array,
      default: () => [],
    },

    noForm: {
      type: Boolean,
      default: false,
    },
  },

  model: { prop: "value", event: "input" },
  data() {
    return {
      createDialog: false,
    };
  },

  computed: {
    ...mapState("contacts", ["contacts"]),

    localValue: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    ...mapActions("contacts", ["getContacts"]),

    async contactFormSubmit() {
      this.createDialog = false;
      await this.getContacts();
    },
  },

  async created() {
    await this.getContacts();
  },
};
</script>
