<template>
  <v-form
    :lazy-validation="true"
    @submit.prevent="handleSubmit"
    v-model="valid"
    ref="form"
  >
    <v-card>
      <v-toolbar v-if="false" color="primary" dark flat>
        <v-toolbar-title>{{
          !this.edit ? $t("common.create") : $t("common.update")
        }}</v-toolbar-title>
        <div class="flex-grow-1"></div>
      </v-toolbar>

      <v-card-text>
        <v-text-field
          :label="$t('actions.fields.title')"
          name="title"
          type="text"
          v-model="payload.title"
          :rules="titleRules"
        ></v-text-field>
        <v-text-field
          :label="$t('actions.fields.description')"
          name="description"
          type="text"
          v-model="payload.description"
        ></v-text-field>

        <v-select
          :label="$t('actions.fields.eventType')"
          name="eventType"
          v-model="payload.eventType"
          :items="eventTypes"
          item-text="key"
          item-value="value"
        ></v-select>

        <v-checkbox
          v-if="showCooldown"
          v-model="payload.cooldownOnAction"
          :label="$t('actions.fields.cooldownOnAction')"
        ></v-checkbox>

        <v-checkbox
          v-if="showCooldown"
          v-model="payload.manualCooldown"
          :label="$t('actions.fields.manualCooldown')"
          :hint="$t('actions.hints.manualCooldown')"
          persistent-hint
        ></v-checkbox>

        <v-text-field
          :label="$t('actions.fields.cooldownMinutes')"
          name="cooldownMinutes"
          type="number"
          v-model="payload.cooldownMinutes"
          v-if="showCooldown"
        ></v-text-field>

        <v-text-field
          :label="$t('actions.fields.duration')"
          name="duration"
          type="number"
          v-model="payload.duration"
          v-if="showDuration"
          :hint="$t('actions.hints.duration')"
          persistent-hint
        ></v-text-field>

        <div v-if="payload.eventType != undefined">
          <p class="text-left title text-bolder mb-0 mt-2">
            {{ $t("actions.actions.title") }}
          </p>
          <p class="text-left subtitle-2 m-0">
            {{ $t("actions.actions.description") }}
          </p>

          <action-type-selector
            v-if="payload.eventType != undefined"
            :eventType="payload.eventType"
            v-model="payload.actionTypesString"
          />

          <action-config-form
            v-if="
              payload.eventType != undefined && payload.actionTypesString != ''
            "
            :eventType="payload.eventType"
            :actions="payload.actionTypesString.split(',')"
            v-model="payload.configString"
          />
        </div>

        <contact-selector
          v-if="showContactSelector"
          v-model="contactIds"
          multi
        />

        <resource-selector
          v-if="showResourceSelector"
          v-model="resourceIds"
          :eventType="payload.eventType"
        />
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" type="submit" x-large>{{
          !this.edit ? $t("common.create") : $t("common.update")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { EventTypes, ActionTypes } from "@/_helpers/actionEvents";

import ContactSelector from "@/components/contact/ContactSelector";
import ResourceSelector from "@/components/action/ResourceSelector";
import ActionTypeSelector from "@/components/action/ActionTypeSelector";
import ActionConfigForm from "@/components/action/ActionConfigForm";

export default {
  name: "ActionForm",

  components: {
    ActionTypeSelector,
    ActionConfigForm,
    ContactSelector,
    ResourceSelector,
  },

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },

    edit: {
      type: Boolean,
      default: false,
    },
  },

  model: {
    prop: "value",
    event: "input",
  },

  data() {
    return {
      EventTypes: EventTypes,

      submitted: false,
      valid: false,
      payload: {
        title: "",
        description: "",
        eventType: EventTypes.NodeOffline,
        actionTypesString: "",
        cooldownOnAction: true,
        cooldownMinutes: 60,
        manualCooldown: false,
        duration: 0,
        configString: "{}",
        contactIds: [],
        resourceIds: [],
      },

      resourceIds: [],
      contactIds: [],

      titleRules: [
        (v) =>
          !!v ||
          this.$t("validation.required", [this.$t("actions.fields.title")]),
        (v) =>
          (v && v.length <= 255) ||
          this.$t("validation.max", [this.$t("actions.fields.title"), 255]),
      ],

      requiredRules: [(v) => !!v || this.$t("validation.required")],
    };
  },

  computed: {
    ...mapState("users", ["hasCompany"]),
    ...mapState("actions", ["eventTypes", "features"]),

    showContactSelector() {
      if (this.payload?.actionTypesString == "") return false;

      let contactActions = [ActionTypes.SMS, ActionTypes.Email];
      return (
        this.payload.actionTypesString
          .split(",")
          .filter((v) => contactActions.includes(v)).length > 0
      );
    },

    showResourceSelector() {
      if (this.payload?.eventType == undefined) return false;

      switch (this.payload?.eventType) {
        case EventTypes.NodeOffline:
        case EventTypes.NodeDataIngestError:
        default:
          return true;
      }
    },

    showDuration() {
      if (this.payload?.eventType == undefined) return false;

      return this.features[this.payload?.eventType]?.usingDuration ?? false;
    },

    showCooldown() {
      if (this.payload?.eventType == undefined) return false;

      return this.features[this.payload?.eventType]?.usingCooldown ?? false;
    },
  },

  methods: {
    ...mapActions("actions", [
      "createAction",
      "updateAction",
      "getFeatures",
      "getEventTypes",
    ]),

    updateDecoder(val) {
      this.decoder = val;
    },

    async handleSubmit() {
      if (!this.$refs.form.validate()) {
        this.valid = false;
        console.error(
          "form validation error",
          this.$refs.form.errors,
          this.payload
        );
        return;
      }

      this.submitted = true;

      this.payload.resourceIds = this.resourceIds;
      this.payload.contactIds = this.contactIds;

      var result = false;
      if (!this.edit) result = await this.createAction(this.payload);
      else result = await this.updateAction(this.payload);

      if (result) this.$router.push("/actions");
      else this.$store.dispatch("alert/error", this.$t("common.error"));
    },
  },

  async created() {
    if (!this.hasCompany) {
      this.$router.push("/");
      return;
    }

    await this.getEventTypes();
    await this.getFeatures();

    if (!this.edit) return;

    if (this.value?.actionId == undefined) {
      this.$store.dispatch(
        "alert/error",
        this.$t("actions.errors.noActionIdFound")
      );
      this.$router.push("/actions");

      return;
    }

    if (Object.entries(this.value).length > 0) {
      // We need to convert the resources and contacts to their respective ids
      let values = { ...this.value, resourceIds: [], contactIds: [] };
      for (var resource of this.value.resources) {
        this.resourceIds.push(resource.identifier);
      }

      for (var contact of this.value.contacts) {
        this.contactIds.push(contact.contactId);
      }

      this.payload = values;
    }
  },
};
</script>
