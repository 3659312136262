<template>
  <v-autocomplete
    v-model="localValue"
    :items="availableActionTypes"
    item-text="title"
    item-value="value"
    :label="$t('actions.fields.actionTypesString')"
    :placeholder="$t('actions.fields.actionTypesString')"
    multiple
    chips
    small-chips
    deletable-chips
    clearable
    dense
  >
  </v-autocomplete>
</template>

<script>
import { ActionTypes } from "@/_helpers/actionEvents";
import { mapState } from "vuex";

export default {
  name: "ActionTypeSelector",

  props: {
    eventType: {
      type: Number,
      required: true,
    },

    value: {
      type: String,
      required: true,
    },
  },

  model: {
    prop: "value",
    event: "input",
  },

  data() {
    return {};
  },

  computed: {
    ...mapState("actions", ["eventTypes", "features"]),

    availableActionTypes() {
      const allActions = [
        {
          title: this.$t("actions.actions.email"),
          value: ActionTypes.Email,
          intValue: 0,
        },
        {
          title: this.$t("actions.actions.sms"),
          value: ActionTypes.SMS,
          intValue: 1,
        },
        {
          title: this.$t("actions.actions.addToAction"),
          value: ActionTypes.AddToAction,
          intValue: 2,
        },
      ];

      return allActions.filter((action) =>
        this.features[this.eventType]?.availableActions.includes(
          action.intValue
        )
      );
    },

    localValue: {
      get() {
        return this.value.split(",");
      },

      set(value) {
        this.$emit("input", value.filter((v) => v).join(","));
      },
    },
  },
};
</script>
