<template>
  <div>
    <div v-if="actionsInclude('email')">
      <v-text-field
        :label="$t('actions.fields.emailTitleTemplate')"
        name="email"
        type="text"
        v-model="emailSubjectTemplate"
        @input="
          (e) => {
            config.emailSubjectTemplate = e;
            $emit('input', JSON.stringify(config));
          }
        "
        hide-details
        mb-0
      ></v-text-field>

      <h5 class="text-left">
        {{ $t("actions.config.emailTitleConfigDescription") }}
        <p class="font-weight-thin">
          {{ $t("actions.config.availableTitleVariables") }}
          {{ $t("actions.config.variables") }}
        </p>
      </h5>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActionConfigForm",

  props: {
    value: {
      type: String,
      default: "{}",
    },

    eventType: {
      type: Number,
      required: true,
    },

    actions: {
      type: Array,
      default: () => [],
    },
  },

  model: {
    prop: "value",
    event: "input",
  },

  data() {
    return {
      emailSubjectTemplate: "",
    };
  },

  computed: {
    config: {
      get() {
        let defaults = {
          emailSubjectTemplate: "",
        };
        if (!this.value) {
          return defaults;
        }

        let parsed = JSON.parse(this.value);
        if (
          Object.keys(parsed).length === 0 ||
          Object.values(parsed).every((v) => v === "")
        ) {
          return defaults;
        }

        return parsed;
      },

      set(value) {
        this.$emit("input", JSON.stringify(value));
      },
    },
  },

  methods: {
    actionsInclude(action) {
      return this.actions.includes(action);
    },

    setValues() {
      this.emailSubjectTemplate = this.config?.emailSubjectTemplate ?? "";
    },
  },

  watch: {
    value: {
      handler(value, newValue) {
        if (value !== newValue) {
          this.setValues(newValue);
        }
      },
      deep: true,
    },
  },

  created() {
    this.setValues(this.value);
  },
};
</script>
