<template>
  <v-form
    :lazy-validation="true"
    @submit.prevent="handleSubmit"
    v-model="valid"
    ref="form"
  >
    <v-card>
      <v-card-text>
        <v-text-field
          prepend-icon="mdi-account-box-outline"
          :label="$t('contacts.fields.name')"
          name="name"
          type="text"
          v-model="localValue.name"
          :rules="[(v) => !!v || $t('validation.requiredSingle')]"
        ></v-text-field>
        <v-text-field
          prepend-icon="mdi-email"
          :label="$t('contacts.fields.email')"
          name="email"
          type="text"
          v-model="localValue.email"
          :rules="[
            (v) => !!v || $t('validation.requiredSingle'),
            (v) =>
              /.+@.+\..+/.test(v) || $t('contacts.validation.emailFormatError'),
          ]"
        ></v-text-field>
        <phone-number-input v-model="localValue.phoneNumber" :validate="true" />
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" type="submit" x-large>{{
          $t("common.create")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ContactForm",

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },

  model: {
    prop: "value",
    event: "input",
  },

  data() {
    return {
      valid: false,
    };
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    ...mapActions("contacts", ["createContact"]),
    async handleSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      await this.createContact(this.localValue);
      this.$emit("submit", this.localValue);
      this.localValue = {}; // Reset form
    },
  },
};
</script>
