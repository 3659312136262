export const ActionTypes = {
  Email: "email",
  SMS: "sms",
  AddToAction: "add to action",
};

export const EventTypes = {
  NodeDataIngestError: 0,
  NodeOffline: 1,
  NodeCreated: 2,
};

export const ActionResourceTypes = {
  Node: 0,
  User: 1,
  Company: 2,
  Gateway: 3,
  Action: 4,
};

export function getResourceTypeByEventType(eventType) {
  switch (eventType) {
    case EventTypes.NodeOffline:
      return ActionResourceTypes.Node;
    case EventTypes.NodeDataIngestError:
      return ActionResourceTypes.Node;
    case EventTypes.NodeCreated:
      return ActionResourceTypes.Action;
    default:
      return null;
  }
}
